<template>
  <Layout v-if="question">
    <div class="row align-items-center">
      <div class="col-6">
        <div class="page-title-box">
          <h3>Perguntas</h3>
        </div>
      </div>
      <div class="col-6">
        <div class="float-right">
          <router-link :to="{ name: 'questions' }" class="btn btn-info">
            <i class="mdi mdi-view-list"></i>
            Lista de perguntas
          </router-link>
        </div>
      </div>
    </div>
    <Question :question="question" :showAccessButton="false" />
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import * as api from "@/api";

import Question from "@/components/Question";

export default {
  page: {
    title: "Pergunta",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    Question,
  },
  data() {
    return {
      form: {
        selected: "",
      },
      options: [],
      isAnswered: false,
      isCorrect: false,
      question: null,
    };
  },

  created() {
    this.loadQuestion();
  },

  methods: {
    loadQuestion() {
      api.getQuestionById(this.$route.params.id).then((question) => {
        this.question = question;
      });
    },

    onSubmit() {
      if (!this.form.selected) {
        return this.$swal(
          "Opa",
          "Você precisa escolher uma alternativa",
          "warning"
        );
      }

      if (this.form.selected === this.realAnswer) {
        this.isAnswered = true;
        this.isCorrect = true;
      } else if (this.form.selected !== this.realAnswer) {
        this.isAnswered = true;
        this.isCorrect = false;
      }
    },
  },
};
</script>
